import type { LocationQuery } from 'vue-router'

import { type Currency, buybackCustomerAPI } from '@backmarket/http-api'
import type { BuybackModelDiagnostic } from '@backmarket/http-api/src/api-specs-buyback/models'
import type { I18n } from '@backmarket/nuxt-module-i18n/types'

export function getOfferAdapter(
  offer: buybackCustomerAPI.GetOfferV1Response,
  queries: LocationQuery,
  currencySign: I18n['currencySign'],
): buybackCustomerAPI.GetOfferResponseLegacy | null {
  const date = new Date()
  const currency = offer.listing.price.currency as Currency

  return {
    title: offer.product.fullTitle,
    thumbnail: offer.product.image,
    price: Number(offer.listing.price.amount),
    price_with_currency: `${offer.listing.price.amount.replace('.', ',')} ${currencySign(currency)}`,
    standard_price: {
      amount: offer.listing.price.amount,
      currency,
    },
    sourcing_listing: Number(offer.listing.listingId),
    date_created: date.toISOString().slice(0, 19).replace('T', ' '),
    wording: offer.instructions,
    diagnostic_summary: offer.checkup,
    // casting LocationQueries to BuybackModelDiagnostic to avoid typing errors
    // if we are here is because the API has already accepted the queries as payload
    diagnostic_payload: {
      ...queries,
    } as BuybackModelDiagnostic,
    has_shipping_choices: offer.shipping.hasChoice,
    hasBankDetails: offer.bankDetailsRequired,
    defaultShippingModeId: offer.shipping.default,
  }
}
